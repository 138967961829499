import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import './UserFormBooking.css'
import QR from '../../img/myqr.jpg'
import { Outlet, useNavigate } from 'react-router-dom';
import constants from '../constants'

export default function AddressForm() {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if(token) {
            const accessToken = atob(token.split('.')[1])
            console.log(atob(token.split('.')[1]))
            if(accessToken.iat * 1000 < Date.now()) {
            localStorage.removeItem('token')
            navigate('/auth/login')
            } else {

            }
        } else {
            localStorage.removeItem('token');
            navigate('auth/login')
        }
        }, []);

    

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [userContact, setUserContact] = useState('')
    const [userAddress, setUserAddress] = useState('')
    const [packageCode, setPackageCode] = useState('')
    const [date, setDate] = useState('')
    const [packagePrice, setPackagePrice] = useState('')
    const [refferenceNumber, setRefferenceNumber] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault();

        const values = {
            firstname: `${firstname}`,
            lastname: `${lastname}`,
            userContact: `${userContact}`,
            userAddress: `${userAddress}`,
            packageCode: `${packageCode}`,
            date: `${date}`,
            packagePrice: `${packagePrice}`,
            refferenceNumber: `${refferenceNumber}`
        }

        console.log(values);

        fetch(`${constants.ENDPOINT}/api/bookings`, {
            method: 'POST', mode: 'cors', headers: {
                "Content-Type": "application/json",
            }, body: JSON.stringify(values)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)

                if (data.success) {
                    navigate('/dashboard/user/view')
                    alert('Book Session Successfully...')
                } else {
                    console.log('Error creating account')
                }
            })
            .catch(err => console.log(err))
    }

    return (
        <Grid container>
            <Grid md={8} className='form-container'>
                <Container maxWidth="md" sx={{ bgcolor: 'gray', color: '#white' }} >
                    <form className='form-radius' onSubmit={handleSubmit}>
                        <div className="row mb-4">
                            <div className="p-4"><h3>Billing Form</h3></div>
                            <div className="col">
                                <div className="form-outline">
                                    <input onChange={(e) => setFirstname(e.target.value)} name='setFirstname' type="text" id="setFirstname" className="form-control" />
                                    <label className="form-label" for="form6Example1">First name</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-outline">
                                    <input onChange={(e) => setLastname(e.target.value)} name='setLastname' type="text" id="setLastname" className="form-control" />
                                    <label className="form-label" for="form6Example2">Last name</label>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col">
                                <div className="form-outline mb-4">
                                    <input onChange={(e) => setUserContact(e.target.value)} name='setUserContact' type="text" id="setUserContact" className="form-control" />
                                    <label className="form-label" for="form6Example6">Contact #</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-outline mb-4">
                                    <input onChange={(e) => setDate(e.target.value)} name='setDate' type="date" id="setDate" className="form-control" />
                                    <label className="form-label" for="form6Example6">Schedule Date</label>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col">
                                <div className="form-outline mb-4">
                                    <input onChange={(e) => setPackagePrice(e.target.value)} name='setPackagePrice' type="text" id="setPackagePrice" className="form-control" />
                                    <label className="form-label" for="form6Example6">Package Price</label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-outline mb-4">
                                    <input onChange={(e) => setPackageCode(e.target.value)} name='setPackageCode' type="text" id="setPackageCode" className="form-control" />
                                    <label className="form-label" for="form6Example6">Session Code</label>
                                </div>
                            </div>
                        </div>

                        <div className="form-outline mb-4">
                            <textarea onChange={(e) => setRefferenceNumber(e.target.value)} name='setRefferenceNumber' className="form-control" id="setRefferenceNumber" rows="1"></textarea>
                            <label className="form-label" for="form6Example7">Put refference # from your GCash for validation.</label>
                        </div>

                        <div className="form-outline mb-4">
                            <textarea onChange={(e) => setUserAddress(e.target.value)} name='setUserAddress' className="form-control" id="setUserAddress" rows="3"></textarea>
                            <label className="form-label" for="form6Example7">Address</label>
                        </div>

                        <button type="submit" className="btn btn-success btn-block mb-4">Submit</button>
                    </form>
                </Container>
                <Outlet />
            </Grid>
            <Grid md={4} className='fluid d-flex align-items-center'>
                <div><img src={QR} alt="scan-qr" /></div>
            </Grid>
        </Grid>
    );
}

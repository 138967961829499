import React from 'react'
import { Outlet } from 'react-router-dom'
import BookingList from './UserBook/BookingList'

const UserDashboard = () => {
    return (
        <>
        <BookingList /><br />
        {/* <div data-tockify-component="mini" data-tockify-calendar="sessionevents"></div> */}
        <Outlet />
        </>
    )
}

export default UserDashboard
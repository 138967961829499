import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import spotify1 from "../../img/spotify/spotify (6).jpg";
import spotify2 from "../../img/spotify/spotify (3).jpg";
import spotify3 from "../../img/spotify/spotify (2).jpg";
import spotify4 from "../../img/spotify/spotify1.jpg";
import spotify5 from "../../img/spotify/spotify (4).jpg";
import spotify6 from "../../img/spotify/spotify (7).jpg";
import Footer from "../../components/Footer/Footer";
import "./AlbumSection.css";

const AlbumSection = () => {
  return (
    <div className="mt-5 fluid albumsection-bg ">
      <div className="mt-5 text-center fw-400"><h1>SONGS</h1></div>
      <div className="container ml-5 cards-content">
        <Row className="mt-5">
          <Col lg={4} md sm className="mt-2">
            <Card style={{ width: "20rem", background: "#212529"  }}>
              <Card.Img variant="top" src={spotify1} />
              <Card.Body>
                <iframe
                  src="https://open.spotify.com/embed/track/02stebNP78MPH4Jy2V1Ku4?utm_source=generator"
                  width="100%"
                  height="80"
                ></iframe>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md sm className="mt-2">
            <Card style={{ width: "20rem", background: "#212529"  }}>
              <Card.Img variant="top" src={spotify2} />
              <Card.Body>
                <iframe
                  src="https://open.spotify.com/embed/track/0J8aNfsH4GmG8T2EQ5n4Xm?si=8bf0078c44be425d"
                  width="100%"
                  height="80"
                ></iframe>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md sm className="mt-2">
            <Card style={{ width: "20rem", background: "#212529"  }}>
              <Card.Img variant="top" src={spotify3} />
              <Card.Body>
                <iframe
                  src="https://open.spotify.com/embed/track/306ceeNFzphnTycVCIIMPh?si=3a8ebf9988ab475b"
                  width="100%"
                  height="80"
                ></iframe>
              </Card.Body>
            </Card>
          </Col>
        {/* </Row>

        <Row className="mt-5"> */}
          <Col lg={4} md sm className="mt-2">
            <Card style={{ width: "20rem", background: "#212529"  }}>
              <Card.Img variant="top" src={spotify4} />
              <Card.Body>
                <iframe
                  src="https://open.spotify.com/embed/track/5OJ1R4e4gfC7PAG86VZHqe?si=170abcf80288433c"
                  width="100%"
                  height="80"
                ></iframe>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md sm className="mt-2">
            <Card style={{ width: "20rem", background: "#212529" }}>
              <Card.Img variant="top" src={spotify5} />
              <Card.Body>
                <iframe
                  src="https://open.spotify.com/embed/track/33TCveMifWkpyEYJNWpXRw?si=824723138ff94c58"
                  width="100%"
                  height="80"
                ></iframe>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md sm className="mt-2">
            <Card style={{ width: "20rem", background: "#212529"  }}>
              <Card.Img variant="top" src={spotify6} />
              <Card.Body>
                <iframe
                  src="https://open.spotify.com/embed/track/3RXksRfC8RjYqmXgDxui0m?si=01d66017c068456c"
                  width="100%"
                  height="80"
                ></iframe>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default AlbumSection;

import React, { useState } from 'react';
import { Link, useNavigate, Outlet } from 'react-router-dom';
import Logo from '../../img/Logo.png';
import AddEvents from '../BookNow/AddEvents';
import "./AdminNav.css"

const AdminNav = () => {
    const navigate = useNavigate();

    const [showAddButton, setshowAddButton] = useState(false);

    const handleButtonClick = (click) => {
        if (click) {
            setshowAddButton(true);
        } else if (!click){
            setshowAddButton(false);
        }
    };


    const logout = () => {
        localStorage.removeItem('userType');
        navigate('/');
    };

    return (
        <div className="d-flex">
            <div
                className="d-flex sticky-top flex-column flex-shrink-0 p-3 text-white bg-dark text-center"
                style={{ width: '250px', height: '100vh' }}
            >
                <Link to="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <span className="fs-4">
                        <img src={Logo} alt="logo-dashboard" style={{ width: '40%' }} />
                    </span>
                </Link>
                <hr />
                <ul className="nav nav-pills flex-column mb-auto">
                    <li>
                        <Link className="btn btn-primary w-100 fw-bold" aria-current="page">
                            Dashboard
                        </Link>
                    </li>
                    <li>
                        <Link to="/dashboard/admin/bookinglist" className="nav-link text-white">
                            Booking List
                        </Link>
                    </li>
                    <li><div className="btn-group" role="group" aria-label="Basic example">
                        <Link to="/dashboard/admin/add_events" className="nav-link text-white" onClick={() => {handleButtonClick(false ? true : false)}}>
                            Add Events
                        </Link>
                        <div to='#' className='add-events-modal'>{!showAddButton && <AddEvents />}</div>
                        </div>
                    </li>
                    <li><div className="btn-group" role="group" aria-label="Basic example">
                        {/* <Link to="#" className="nav-link text-white" onClick={() => {handleButtonClick(true ? true : false)}}>
                            Add Gigs
                        </Link>
                        <div className='add-events-modal'>{showAddButton && <AddEvents />}</div> */}
                        </div>
                    </li>
                    {/* <li>
                        <Link to="/dashboard/admin/settings" className="nav-link text-white">
                            Settings
                        </Link>
                    </li> */}
                </ul>
                <p>
                    <div className="btn-group" role="group" aria-label="Basic example">
                        <Link type="button" to="/" class="btn btn-primary btn-sm">
                            Home
                        </Link>
                        <Link type="button" class="btn btn-danger btn-sm" onClick={() => logout()}>
                            Logout
                        </Link>
                    </div>
                    <hr />
                    Copyright ©2023 All rights reserved | Joseph Gara
                </p>
            </div>
            <Outlet />
        </div>
    );
};

export default AdminNav;

import React, { useState } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
}
from 'mdb-react-ui-kit';
import './Register.css'
import { Link, useNavigate } from 'react-router-dom';
import constants from '../constants'

const Register = () => {

  const navigate = useNavigate()

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  

  const handleSubmit = (e) => {
    e.preventDefault();

    const values = {
      firstname: `${firstname}`,
      lastname: `${lastname}`,
      email: `${email}`,
      password: `${password}`
    }
    console.log(values);

  fetch(`${constants.ENDPOINT}/api/users`, {method: 'POST', mode: 'cors', headers: {
      "Content-Type": "application/json",
    }, body: JSON.stringify(values)})
      .then(res => res.json())
      .then(data => {
        console.log(data)

        if (data.success) {
          console.log('Registered Successfully...')
          navigate('/auth/login')
        } else {
          console.log('Error creating account')
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <MDBContainer fluid className='vh-100 justify-content-center align-content-center'>
      <form onSubmit={handleSubmit}>
      <MDBRow className='d-flex justify-content-center align-items-center vh-100'>
        <MDBCol col='12'>

          <MDBCard className='bg-dark text-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '500px'}}>
            <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>

              <h2 className="fw-bold mb-4 text-uppercase">Register</h2>

              <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass='text-white' name='fullname' placeholder='Firstname' type='text' size="lg"
              onChange={(e)=>setFirstname(e.target.value)}
              />
              <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass='text-white' name='fullname' placeholder='Lastname' type='text' size="lg"
              onChange={(e)=>setLastname(e.target.value)}
              />
              <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass='text-white' name='email' placeholder='Email' type='email' size="lg"
              onChange={(e)=>setEmail(e.target.value)}
              />
              <MDBInput wrapperClass='mb-4 mx-5 w-100' labelClass='text-white' name='password' placeholder='Password' type='password' size="lg"
              onChange={(e)=>setPassword(e.target.value)}
              />

              <button type='submit' className='mx-2 px-5 btn btn-primary text-white btn-lg'>Register</button>

              <div className='mt-3 text-center'>
              <p className="mb-0">Don't have an account? <Link to='/api/users' className="fw-bold text-decoration-none text-dark badge bg-info">Login</Link></p> <br />
                <p className="mb-0"><Link to='/' className="fw-bold text-decoration-none btn-sm btn-info btn">Homepage</Link></p>
              </div>
            </MDBCardBody>
          </MDBCard>

        </MDBCol>
      </MDBRow>
      </form>
    </MDBContainer>
  );
}

export default Register;
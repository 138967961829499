import React from "react";
import './AdminSettings.css'

const AdminSettings = () => {
  return (
    <div className="centered-table">
        <h2 className="text-center">ADMIN SETTINGS</h2>
        <div className="upload-section">
            <img src="#" alt="Profile" />
            <input type="file" name="profile-picture" id="profile-picture" />
        </div>
        <table className="profile-table">
            <tbody>
            <tr>
                <td>First Name:</td>
            </tr>
            <tr>
                <td>Last Name:</td>
            </tr>
            <tr>
                <td>Contact:</td>
            </tr>
            <tr>
                <td>Address:</td>
            </tr>
            </tbody>
        </table>

        <div className="edit-button">
            <button>EDIT</button>
        </div>
    </div>
  );
};

export default AdminSettings;

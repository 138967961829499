import { Container, Carousel } from 'react-bootstrap';
import pic1 from '../../img/about-carousel/1.png'
import pic2 from '../../img/about-carousel/2.png';
import pic3 from '../../img/about-carousel/3.png';
import pic4 from '../../img/about-carousel/4.png';
import pic5 from '../../img/about-carousel/5.png';
import pic6 from '../../img/about-carousel/6.png';
import pic7 from '../../img/about-carousel/7.png';
import pic8 from '../../img/about-carousel/8.png';
import m1 from '../../img/about-carousel/Bassist.png';
import m2 from '../../img/about-carousel/Drummer.png';
import m3 from '../../img/about-carousel/Percussionist.png';
import m4 from '../../img/about-carousel/guitarist1.png';
import picheader from '../../img/group.jpg';
import m5 from '../../img/about-carousel/guitar2.png';
import m6 from '../../img/about-carousel/keyboard2.png';
import m7 from '../../img/about-carousel/keyboard1.png';
import m8 from '../../img/about-carousel/bandurist.png';
import React, { useState } from 'react';
import Footer from '../Footer/Footer';
import F from '../../img/group-members.jpg'

import '../../style.css';
import './Page.css'

const About = () => {

    return (
      <div className='about-body'>
        
        <Container className='text-center mt-5'>
        <Carousel>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100 rounded" src={pic1} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100 rounded" src={pic2} alt="Second slide" />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100 rounded" src={pic3} alt="Third slide" />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100 rounded" src={pic4} alt="Third slide" />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100 rounded" src={pic5} alt="Third slide" />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100 rounded" src={pic6} alt="Third slide" />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100 rounded" src={pic7} alt="Third slide" />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img className="d-block w-100 rounded" src={pic8} alt="Third slide" />
          </Carousel.Item>
        </Carousel>
        </Container>

        <Container className="text-center px-5 mt-4">
          <h1>About</h1>
          <p>
            Joseph Gara performs an original live music in a Corporate events
            together with his most trusted band sessionist. His band sessionist
            has the skilled in playing multiple instruments and has a stong
            ability to adapt their musical style to suit a various genres and
            musical arrangements. They seamlessly integrate into Joseph Gara's
            creative vision by providing the necessary musical support to bring
            jis music to life. They are also a great team players, providing,
            the foundation, harmony and rhythm that enhance overall sound of
            Joseph Gara's music.
          </p>
        </Container>

        <Container fluid className='text-center h-50 w-50'>
          <img src={F} alt='pic-header-about' className='responsive rounded'/>
        </Container>

        <h1 class='text-center mt-5 text-white'>Sessionist</h1>
        <div class="container text-center mt-5">
          <div class="row text-center">
            <div class="col d-flex justify-content-center align-items-center">
              <div>
                <h3>Arvin Avergonzago</h3>
                <p>Drummer</p>
              </div>
              <div>
                <img src={m2} />
              </div>
            </div>
            <div class="col d-flex justify-content-center align-items-center">
              <div>
                <h3>Jaime Gara</h3>
                <p>Bassist</p>
              </div>
              <div>
                <img src={m1} />
              </div>
            </div>
          </div>
          <div class="row text-center mt-5">
            <div class="col d-flex justify-content-center align-items-center">
              <div>
                <h3>Anthony Dabalos</h3>
                <p>Percussionist</p>
              </div>
              <div>
                <img src={m3} />
              </div>
            </div>
            <div class="col d-flex justify-content-center align-items-center">
              <div>
                <h3>Philip Quinga</h3>
                <p>Keyboardist 1</p>
              </div>
              <div>
                <img src={m7} />
              </div>
            </div>
          </div>
          <div class="row text-center mt-5">
            <div class="col d-flex justify-content-center align-items-center">
              <div>
                <h3>Baylon Botardo</h3>
                <p>Keyboardist 2</p>
              </div>
              <div>
                <img src={m6} />
              </div>
            </div>
            <div class="col d-flex justify-content-center align-items-center">
              <div>
                <h3>Sam Varquez</h3>
                <p>Guitarist 1</p>
              </div>
              <div>
                <img src={m4} />
              </div>
            </div>
          </div>
          <div class="row text-center mt-5">
            <div class="col d-flex justify-content-center align-items-center">
              <div>
                <h3>Seigfred Bandico</h3>
                <p>Guitarist 2</p>
              </div>
              <div>
                <img src={m5} />
              </div>
            </div>
            <div class="col d-flex justify-content-center align-items-center">
              <div>
                <h3>Loida Romea</h3>
                <p>Bandurist</p>
              </div>
              <div>
                <img src={m8} />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
}
 
export default About;
import React, { useState, useEffect } from "react";
import CardItem from "./BookNowItem";
import { Container, Row, Col } from "react-bootstrap";
import constants from '../constants'
import { useNavigate } from "react-router-dom";

const BookNow = () => {
  const [schedEvent, setSchedEvents] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    refreshEvents()
    const token = localStorage.getItem('token');
    if(token) {
      const accessToken = atob(token.split('.')[1])
      console.log(atob(token.split('.')[1]))
      if(accessToken.iat * 1000 < Date.now()) {
        localStorage.removeItem('token')
        navigate('/auth/login')
      }
    }
  }, []);

  const refreshEvents = () => {
    fetch(`${constants.ENDPOINT}/api/packages`, {method: 'GET', mode: 'cors'})
    .then(response => response.json())
    .then(data => {
      setSchedEvents(data)
      console.log(data);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }

    return (
        <Container className="my-5 text-center">
            <Row className="my-5">
                {schedEvent && schedEvent.map(item => (
                    <Col key={item.id} md={4}>
                        <CardItem id={item.id} image={item.image} info={item.info} price={item.price} package={item.packageCode} refresh={refreshEvents}/>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default BookNow;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter} from 'react-router-dom';
import Login from './components/Login/Login';
import ErrorView from './components/ErrorView/ErrorView';
import Navbar from './components/Navbar/Navbar';
import About from './components/pages/About';
import Story from './components/pages/Story';
import HeroSection from './components/HeroSection/HeroSection';
import BookNow from './components/BookNow/BookNow';
import Register from './components/Register/Register';
import BookingList from './components/Panel/Booking List/BookingList';
import UserNav from './components/Navbar/UserNav';
import AdminNav from './components/Navbar/AdminNav';
import IncomingGigs from './components/Gigs/IncomingGigs';
import UserSettings from './components/SettingsInfo/UserSettings';
import AddGigs from './components/Gigs/AddGigs';
import AdminSettings from './components/SettingsInfo/AdminSettings';
import AddEvents from './components/BookNow/BookNow';
import GigsPreview from './components/Gigs/GigsPreview';
import UserDashboard from './components/Dashboard/UserDashboard';
import AdminDashboard from './components/Dashboard/AdminDashboard';
import UserFormBooking from './components/BookNow/UserFormBooking';

// create router
const router = createBrowserRouter([
  
  {
    path: "/",
    element: <Navbar />,
    errorElement: <ErrorView />,
    children: [
      {
        path: "/",
        element: <HeroSection />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/story",
        element: <Story />,
      },
      {
        path: "/gigs",
        element: <GigsPreview />,
      },
    ],
  },
  {
    path: "/dashboard/user",
    element: <UserNav />,
    errorElement: <ErrorView />,
    children: [
      {
        path: "/dashboard/user/view",
        element: <UserDashboard />,
      },
      {
        path: "/dashboard/user/schedule_session",
        element: <BookNow />,
      },
      {
        path: '/dashboard/user/schedule_session/form',
        element: <UserFormBooking />
      },
      {
        path: "/dashboard/user/incoming_session",
        element: <IncomingGigs />,
      },
      {
        path: "/dashboard/user/settings",
        element: <UserSettings />,
      },
    ]
  },
  {
    path: "/dashboard/admin",
    element: <AdminNav />,
    errorElement: <ErrorView />,
    children: [
      // {
      //   path: "/dashboard/admin",
      //   element: <AdminDashboard />,
      // },
      {
        path: "/dashboard/admin/bookinglist",
        element: <BookingList />,
      },
      {
        path: "/dashboard/admin/add_events",
        element: <AddEvents />,
      },
      {
        path: "/dashboard/admin/add_gigs",
        element: <AddGigs />,
      },
      {
        path: "/dashboard/admin/settings",
        element: <AdminSettings />,
      }
    ]
  },
  {
    path: "/auth/login",
    element: <Login />,
    errorElement: <ErrorView />,
  },
  {
    path: "/register",
    element: <Register />,
    errorElement: <ErrorView />,
  },
  
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <RouterProvider router={router} />

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

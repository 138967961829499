import React, { useEffect, useState } from 'react';
import { Modal, Button, Container, Row, Col, Form } from 'react-bootstrap';
import './AddEvents.css';
import { Link } from 'react-router-dom';
import constants from '../constants'

function MyVerticallyCenteredModal(props) {

    console.log(props.card.id)

    const [editEvent, setEditEvent] = useState([])

    useEffect(() => {
        setEditEvent(props.card)
    }, [])

    const handleSubmit = (e) => {
    e.preventDefault();
    
    const values = {...editEvent}
    console.log(values);

    fetch(`${constants.ENDPOINT}/api/packages/${props.card.id}`, {method: 'PUT', mode: 'cors', headers: {
        "Content-Type": "application/json",
        }, body: JSON.stringify(values)})
        .then(res => res.json())
        .then(data => {
            console.log(data)
            if (data.success) {
            console.log('Edit Successfully...')
            props.card.refresh()
            props.onHide()
            }
        })
        .catch(err => console.log(err))


    };

    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark" id="contained-modal-title-vcenter">
                        Edit Events
                        {
                            console.log(editEvent)
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="grid-example">
                    <Container key={editEvent.id}>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3 text-dark" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control
                                        placeholder ='Price'
                                        name='price'
                                        type='text'
                                        // defaultValue={editEvent.price}
                                        value = {editEvent.price}
                                        onChange={(e) => {setEditEvent({...editEvent, price: e.target.value})}}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3 text-dark" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Package Code</Form.Label>
                                    <Form.Control
                                        placeholder ='Package'
                                        name='packageCode'
                                        type='text'
                                        // defaultValue={editEvent.packageCode}
                                        value = {editEvent.package}
                                        onChange={(e) => {setEditEvent({...editEvent, package: e.target.value, packageCode: e.target.value})}}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3 text-dark" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Information</Form.Label>
                                    <Form.Control
                                        placeholder ='Information'
                                        name='info'
                                        type='text'
                                        // defaultValue={editEvent.info}
                                        value = {editEvent.info}
                                        onChange={(e) => {setEditEvent({...editEvent, info: e.target.value})}}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit">Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

const AddEvents = ({card}) => {
    const [modalShow, setModalShow] = useState(false);

    return (
        <Container>
            {/* <img
                onClick={() => setModalShow(true)}
                width="25"
                height="25"
                src="https://img.icons8.com/fluency/48/add.png"
                alt="add-events"
            /> */}
            <Link type='button' className='btn btn-success w-100' onClick={() => setModalShow(true)}>EDIT</Link>
            <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} card={card} />
        </Container>
    );
};

export default AddEvents;

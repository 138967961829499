import './MusicNotes.css'

const MusicNotes = () => {
    return (
        <>
            <div class="noot-1">
                &#9835; &#9833;
            </div>
            <div class="noot-2">
                &#9833;
            </div>
            <div class="noot-3">
                &#9839; &#9834;
            </div>
            <div class="noot-4">
                &#9834;
            </div>
        </>
    );
}

export default MusicNotes;
import React, { useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import EditEvents from "./EditEvents";
import constants from '../constants'

const BookNowItem = (card) => {
    const navigate = useNavigate();

    useEffect(() => {
    const token = localStorage.getItem('token');
    if(token) {
        const accessToken = atob(token.split('.')[1])
        console.log(atob(token.split('.')[1]))
        if(accessToken.iat * 1000 < Date.now()) {
        localStorage.removeItem('token')
        navigate('/auth/login')
        } else {

        }
    } else {
        localStorage.removeItem('token');
        navigate('auth/login')
    }
    }, []);
    
    const handleDeleteEvent = (id) => {
        console.log(card)
        console.log(id)
        fetch(`${constants.ENDPOINT}/api/packages/${id}`, {
            method: 'DELETE',
            mode: 'cors'
        })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    console.log('Event deleted successfully...');
                    card.refresh()
                }
            })
            .catch(err => console.log(err))
    };


    return (
        <>
            <div className="card text-end">
                <img src={`${constants.ENDPOINT}/uploads/${card.image}`} alt='event-poster' style={{ width: '100%' }} />
                <div className="card-body text-end">
                    <p className="card-text badge bg-dark" style={{ fontSize: '15px' }}>{card.price}</p>
                    <h3 className="card-title">{card.package}</h3>
                    <p className="card-text">{card.info}</p>
                    <div className="d-flex col">
                    {
                        localStorage.getItem('userType') == 'user'
                        ? ""
                        : <Link onClick={() => handleDeleteEvent(card.id)} className="btn btn-danger w-100">DELETE</Link> 
                    }
                    
                    {
                        localStorage.getItem('userType') == 'user'
                        ? <Link to='/dashboard/user/schedule_session/form' className="btn btn-primary">BOOK NOW</Link> 
                        : <EditEvents card={card} />
                    }
                    </div>
                </div>
            </div>
            <Outlet />
        </>
    );
}

export default BookNowItem;
import { Container, Carousel, Row, Col } from 'react-bootstrap'
import group from '../../img/group.jpg'
import './Page.css'
import j1 from '../../img/story-carousel/joseph/1.png'
import j2 from '../../img/story-carousel/joseph/2.png'
import j3 from '../../img/story-carousel/joseph/3.png'
import j4 from '../../img/story-carousel/joseph/4.png'
import j5 from '../../img/story-carousel/joseph/5.png'
import j6 from '../../img/story-carousel/joseph/6.png'
import j7 from '../../img/story-carousel/joseph/7.png'
import j8 from '../../img/story-carousel/joseph/8.png'
import j9 from '../../img/story-carousel/joseph/9.png'
import j10 from '../../img/story-carousel/joseph/10.png'
import h1 from '../../img/story-carousel/session/s1.png'
import h2 from '../../img/story-carousel/session/s2.png'
import h3 from '../../img/story-carousel/session/s3.png'
import h4 from '../../img/story-carousel/session/s4.png'
import h5 from '../../img/story-carousel/session/s5.png'
import h6 from '../../img/story-carousel/session/s6.png'
import h7 from '../../img/story-carousel/session/s7.png'
import Footer from '../Footer/Footer'

const About = () => {
    return (
        <div className='story-body'>
            <Container fluid className='text-center'>
                <img src={group} alt="pic-header-story" className='responsive rounded'/>
            </Container>

            <Container className='text-center mt-5'>
                <h1>The Singer-Songwriter</h1>
            </Container>

            <Container className='mt-5 '>
                <Carousel>
                    <Carousel.Item interval={2500}>
                        <Row>
                            <Col>
                                <img className="d-block w-100 rounded" src={j1} alt="First slide" />
                            </Col>
                            <Col>
                                <img className="d-block w-100 rounded" src={j2} alt="First slide" />
                            </Col>
                        </Row>
                    </Carousel.Item>
                    <Carousel.Item interval={2500}>
                        <Row>
                            <Col>
                                <img className="d-block w-100 rounded" src={j3} alt="First slide" />
                            </Col>
                            <Col>
                                <img className="d-block w-100 rounded" src={j4} alt="First slide" />
                            </Col>
                        </Row>
                    </Carousel.Item>
                    <Carousel.Item interval={2500}>
                        <Row>
                            <Col>
                                <img className="d-block w-100 rounded" src={j5} alt="First slide" />
                            </Col>
                            <Col>
                                <img className="d-block w-100 rounded" src={j6} alt="First slide" />
                            </Col>
                        </Row>
                    </Carousel.Item>
                    <Carousel.Item interval={2500}>
                        <Row>
                            <Col>
                                <img className="d-block w-100 rounded" src={j7} alt="First slide" />
                            </Col>
                            <Col>
                                <img className="d-block w-100 rounded" src={j8} alt="First slide" />
                            </Col>
                        </Row>
                    </Carousel.Item>
                    <Carousel.Item interval={2500}>
                        <Row>
                            <Col>
                                <img className="d-block w-100 rounded" src={j9} alt="First slide" />
                            </Col>
                            <Col>
                                <img className="d-block w-100 rounded" src={j10} alt="First slide" />
                            </Col>
                        </Row>
                    </Carousel.Item>
                </Carousel>
            </Container>

            <Container className='mt-3'>
                <Row className='ps-4 g-4'>
                    <Col className='sm-md-lg-xl-xxl'>
                        <h5 className='col d-flex justify-content-center align-items-center px-4'>
                            Joseph Gara was born and raised in Bohol, Philippines. He is a singer-songwriter extraordinaire!
                            With a voice that dances on the ears and captivates the soul, he weave words into vivid tapestries
                            of emotion crafted just for us. Through the string of his ukelele and guitar, He paint a portrait of
                            vulnerability, resilience, and unspoken truths. Prepare to be spellbound by his irresistible charisma,
                            as he take us on a breathtaking journey through his musical tapestry, leaving us craving more with each
                            note. It will be an unforgettable experience as this artist effortlessly combine the power of his voice
                            with the art of storytelling, creating a symphony of emotions that will resonate within us for long
                            after a last cord is played. Every song is a masterpiece, and his enchanting presence on stage will leave
                            you mesmerized.</h5>
                    </Col>
                    <Col className='sm-md-lg-xl-xxl'>
                        <iframe width="600" height="350" src="https://www.youtube.com/embed/UAZzjgoHIQE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </Col>
                </Row>
            </Container>

            <Container className='mt-3'>
                <Carousel>
                    <Carousel.Item interval={2500}>
                        <Row>
                            <Col>
                                <img className="d-block w-100 rounded" src={h1} alt="First slide" />
                            </Col>
                            <Col>
                                <img className="d-block w-100 rounded" src={h2} alt="First slide" />
                            </Col>
                        </Row>
                    </Carousel.Item>
                    <Carousel.Item interval={2500}>
                        <Row>
                            <Col>
                                <img className="d-block w-100 rounded" src={h3} alt="First slide" />
                            </Col>
                            <Col>
                                <img className="d-block w-100 rounded" src={h4} alt="First slide" />
                            </Col>
                        </Row>
                    </Carousel.Item>
                    <Carousel.Item interval={2500}>
                        <Row>
                            <Col>
                                <img className="d-block w-100 rounded" src={h5} alt="First slide" />
                            </Col>
                            <Col>
                                <img className="d-block w-100 rounded" src={h6} alt="First slide" />
                            </Col>
                        </Row>
                    </Carousel.Item>
                    <Carousel.Item interval={2500}>
                        <Row>
                            <Col>
                                <img className="d-block w-100 rounded" src={h7} alt="First slide" />
                            </Col>
                            <Col>
                                <img className="d-block w-100 rounded" src={h6} alt="First slide" />
                            </Col>
                        </Row>
                    </Carousel.Item>
                </Carousel>
            </Container>

            <Container className='mt-3'>
                <Row className='ps-4 g-4'>
                    <Col>
                        <h5 className='col d-flex justify-content-center align-items-center px-4'>
                            Joseph Gara performs an original live music in a Corporate events together
                            with his most trusted band sessionist.His band sessionist has the skilled in
                            playing multiple instruments and has a strong ability to adapt their musical
                            style to suit a various genres and musical arrangements. They seamlessly
                            integrate into Joseph Gara's creative vision by providing the necessary musical
                            support to bring his music to life. They are also a great team players, providing,
                            the foundation, harmony and rhythm that enhance overall sound of Joseph Gara's music.
                        </h5>
                    </Col>
                    <Col>
                        <iframe width="600" height="350" src="https://www.youtube.com/embed/khc9cMZ9LF4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </Col>
                </Row>
            </Container>

            <Container className='mt-3'>
                <Row className='ps-4 g-4'>
                    <Col>
                        <iframe style={{ borderRadius: '12px' }} src="https://open.spotify.com/embed/artist/31M83eWJM6J8qCaeUMLIWF?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowfullscreen="" autoplay clipboardWrite encryptedMedia fullscreen loading="lazy"></iframe>
                    </Col>
                    <Col>
                        <h5 className='col d-flex justify-content-center align-items-center px-4'>
                            "Soulful Whispers": Immerse yourself in the mesmerizing melodies and captivating lyrics of this enchanting playlist
                            curated by a gifted singer-songwriter. With his heartfelt compositions and soul-stirring vocals, each track is a
                            musical tapestry that weaves together raw emotions, personal stories, and universal experiences. From tender ballads
                            that tug at the heartstrings to empowering anthems that ignite the spirit, this playlist is a melodic journey that
                            will leave you enchanted and inspired. Let the sincere whispers of this singer-songwriter's soul wash over you, as you
                            lose yourself in the irresistible charm of "Soulful Whispers".
                        </h5>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>

    );
}

export default About;
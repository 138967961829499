import React, { useEffect, useState } from "react";
import HeroVideo from '../../img/hero-video.mp4'
import Button from "../Buttons/Buttons";
import './HeroSection.css'
import { Outlet } from "react-router-dom";
import MusicNotes from "../Animation/MusicNotes";
import AlbumSection from "./AlbumSection";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {

    const navigate = useNavigate()

    return ( 
        <>
        <div className="hero-container">
            <video autoPlay loop muted>
                <source src={HeroVideo} type='video/mp4' />
            </video>
            <h1><span className="color-ga">GA</span><span className="color-ra">RA</span>-<span className="color-on">ONS</span><span className="shadow-text">Place</span></h1>
            <p>Original Live Music</p>
            {
                // localStorage.getItem('userType') == 'user' || localStorage.getItem('userType') == 'admin'
                // ? <Button onClick={() => navigate('/dashboard/user/schedule_session')} className="btn" buttonStyle='btn--primary' buttonSize='btn--large'>BOOK US NOW <i className="fa-solid fa-book" /></Button>
                // : <Button onClick={() => navigate('/auth/login')} className="btn" buttonStyle='btn--primary' buttonSize='btn--large'>BOOK US NOW <i className="fa-solid fa-book" /></Button>

                localStorage.getItem('userType') == 'user' ? 
                    (<Button onClick={() => navigate('/dashboard/user/schedule_session')} className="btn" buttonStyle='btn--primary' buttonSize='btn--large'>BOOK US NOW <i className="fa-solid fa-book" /></Button>) : 
                    ( localStorage.getItem('userType') == 'admin' ? 
                        (<Button onClick={() => navigate('/dashboard/admin/bookinglist')} className="btn" buttonStyle='btn--primary' buttonSize='btn--large'>BOOK US NOW <i className="fa-solid fa-book" /></Button>) : 
                        (<Button onClick={() => navigate('/auth/login')} className="btn" buttonStyle='btn--primary' buttonSize='btn--large'>BOOK US NOW <i className="fa-solid fa-book" /></Button>)
                    )

                // condition ? () : (condition ? () : ())
            }
        </div>
        <MusicNotes />
        <AlbumSection />
        <Outlet />
        </>
    );
}

export default HeroSection;
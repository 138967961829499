import React from 'react'
import './BookingList.css'
import BookingListItem from './BookingListItem';
import { useState, useEffect } from'react';
import constants from '../../constants'

function BookingList() {

    //variable to hold the bookings
    const [bookings, setBookings] = useState([])

    useEffect(() => {
        fetch(`${constants.ENDPOINT}/api/bookings`, {method: 'GET', mode: 'cors'})
    .then(res => res.json())
    .then(data => {
        setBookings(data.data)
    })
    .catch(err => console.log(err))
    }, [])

    return (
        <>
        <section className="vh-80 w-100 mt-5 p-3">
            <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center h-100">
                    <div className="col-md-12 col-xl-10">

                        <div className="card mask-custom">
                            <div className="card-body pt-2 text-white">

                                <div className="text-center">
                                        <h2 className="my-4">Your Scheduled Booking</h2>
                                </div>

                                <table className="table text-white mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">User</th>
                                            <th scope="col">Contact #</th>
                                            <th scope="col">Address</th>
                                            <th scope="col">Code</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Refference #</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bookings.map(item => {
                                            return (
                                                <BookingListItem key={item.id} item={item} />
                                            )})}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
};

export default BookingList
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import img1 from '../../img/previous gigs/previous.png';
import img2 from '../../img/previous gigs/p1.png';
import img3 from '../../img/previous gigs/p2.png';
import img4 from '../../img/previous gigs/p3.png';
import img5 from '../../img/previous gigs/p4.png';
import img6 from '../../img/previous gigs/p5.jpg';
import img7 from '../../img/previous gigs/p6.png';
import img8 from '../../img/previous gigs/p7.png';
import img9 from '../../img/previous gigs/p8.png';

const GigsPreview = () => {
  return (
    <>
      <Container className="mt-5 text-center">
      <Row>
          <Col><h1>PREVIOUS GIGS</h1></Col>
        </Row>
        <Row className='mt-3'>
          <Col xs={4}><img src={img1} alt="sample" style={{width: '100%', height: 'auto'}} /></Col>
          <Col xs={4}><img src={img2} alt="sample" style={{width: '100%', height: 'auto'}} /></Col>
          <Col xs={4}><img src={img3} alt="sample" style={{width: '100%', height: 'auto'}} /></Col>
        </Row>
        <Row className='mt-3'>
          <Col xs={4}><img src={img4} alt="sample" style={{width: '100%', height: 'auto'}} /></Col>
          <Col xs={4}><img src={img5} alt="sample" style={{width: '100%', height: 'auto'}} /></Col>
          <Col xs={4}><img src={img6} alt="sample" style={{width: '100%', height: 'auto'}} /></Col>
        </Row>
        <Row className='mt-3'>
          <Col xs={4}><img src={img7} alt="sample" style={{width: '100%', height: 'auto'}} /></Col>
          <Col xs={4}><img src={img8} alt="sample" style={{width: '100%', height: 'auto'}} /></Col>
          <Col xs={4}><img src={img9} alt="sample" style={{width: '100%', height: 'auto'}} /></Col>
        </Row>
      </Container>
    </>
  );
};

export default GigsPreview;

import React, { useState, useEffect } from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon,
} from 'mdb-react-ui-kit';
import { Link, useNavigate } from 'react-router-dom';
import constants from '../constants'


const Login = () => {

  const navigate = useNavigate()
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const values = {
      email: email,
      password: password,
    }

    fetch(`${constants.ENDPOINT}/api/auth/login`, {method: 'POST', mode: 'cors', headers: {
      "Content-Type": "application/json",
    }, body: JSON.stringify(values)})
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if(result.success) {
          localStorage.setItem('token', result.token);
          localStorage.setItem('userType', result.data);
          result.data === 'user' ? navigate('/dashboard/user/view') : navigate('/dashboard/admin')
        } else {
          localStorage.setItem('userType', '');
          alert('Invalid login.')
        }
      })
      .catch(error => console.error(error));
  }

  useEffect(() => {
    const auth = localStorage.getItem('userType');
    if(auth === 'user') {
      navigate('/');
    } 
    
    if (auth === 'admin') {
      navigate('/dashboard/admin')
    }
  }, [])
  
  return (
    <>
      <MDBContainer fluid className='vh-100 justify-content-center align-content-center'>
        <form onSubmit={handleSubmit}>
          <MDBRow className='vh-100 justify-content-center align-content-center'>
            <MDBCol col='12'>

              <MDBCard className='bg-dark text-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '400px' }}>
                <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>

                  <h2 className="fw-bold mb-4 text-uppercase">Login</h2>

                  <MDBInput name='email' wrapperClass='mb-4 mx-5 w-100' labelClass='text-white' label='Email' type='email' size="lg" 
                  onChange={(e) => setEmail(e.target.value)}
                  />
                  <MDBInput name='password' wrapperClass='mb-4 mx-5 w-100' labelClass='text-white' label='Password' type='password' size="lg" 
                  onChange={(e) => setPassword(e.target.value)}
                  />

                  <p className="small mb-3 pb-lg-2"><a class="text-white-50" href="#!">Forgot password?</a></p>

                  <button type='submit' className='mx-2 px-5 btn btn-primary text-white btn-lg'>Login</button>

                  <div className='d-flex flex-row mt-3 mb-3'>
                    <MDBBtn tag='a' color='none' className='m-3' style={{ color: 'white' }}>
                      <MDBIcon fab icon='facebook-f' size="lg" />
                    </MDBBtn>

                    <MDBBtn tag='a' color='none' className='m-3' style={{ color: 'white' }}>
                      <MDBIcon fab icon='twitter' size="lg" />
                    </MDBBtn>

                    <MDBBtn tag='a' color='none' className='m-3' style={{ color: 'white' }}>
                      <MDBIcon fab icon='google' size="lg" />
                    </MDBBtn>
                  </div>

                  <div className='text-center'>
                    <p className="mb-0">Don't have an account? <Link to='/register' className="fw-bold text-decoration-none text-dark badge bg-info">Register</Link>
                    </p> <br />
                    <p className="mb-0"><Link to='/' className="fw-bold text-decoration-none btn-sm btn-info btn">Homepage</Link></p>
                  </div>
                </MDBCardBody>
              </MDBCard>

            </MDBCol>
          </MDBRow>
        </form>
      </MDBContainer>
    </>
  );
}

export default Login;
import React, { useEffect, useState } from 'react';
import { Container, Modal, Button } from 'react-bootstrap';
import constants from '../constants';

const UserSettings = () => {
    const [getInfo, setGetInfo] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editedInfo, setEditedInfo] = useState({
        firstname: '',
        lastname: '',
        email: '',
        userContact: '',
        userAddress: '',
    });

    const token = localStorage.getItem('token');
    const accessToken = atob(token.split('.')[1])
            console.log(accessToken)

    useEffect(() => {
        fetch(`${constants.ENDPOINT}/api/bookings/`, {
            method: 'GET',
            mode: 'cors',
        })
            .then(res => res.json())
            .then(result => {
                console.log(result.data);
                setGetInfo(result.data);
            })
            .catch(err => console.log(err));
    }, []);

    const handleEdit = info => {
        setEditedInfo(info);
        setShowModal(true);
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setEditedInfo(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSave = () => {
        
        fetch(`${constants.ENDPOINT}/api/bookings/`, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Contenty/Type': 'application/json'
            },
            body: JSON.stringify(editedInfo)
        })
            .then(res => res.json())
            .then(result => {
                console.log(result.data);
                setGetInfo(result.data);
            })
            .catch(err => console.log(err));

        setShowModal(false);
    };

    return (
        <Container className='mt-5'>
            <table className='table px-5'>
                <thead className='text-center'>
                    <tr>
                        <th scope='col' colSpan='2'>
                            <img
                                src='https://www.pngitem.com/pimgs/m/264-2647677_avatar-icon-human-user-avatar-svg-hd-png.png'
                                alt='avatar'
                                style={{ width: '200px' }}
                            />
                        </th>
                    </tr>
                </thead>
                {getInfo.map(info => {
                    return (
                        <tbody key={info.id}>
                            <tr>
                                <td>Firstname: {info.firstname}</td>
                                <td>Contact#: {info.userContact}</td>
                            </tr>
                            <tr>
                                <td>Lastname: {info.lastname}</td>
                                <td>Email: {info.email}</td>
                            </tr>
                            <tr>
                                <td colSpan='2'>Address: {info.userAddress}</td>
                            </tr>
                            <tr className='text-end'>
                                <td colSpan='2'>
                                    <button
                                        type='button'
                                        className='btn btn-success btn-sm'
                                        onClick={() => handleEdit(info)}
                                    >
                                        Edit Info
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    );
                })}
            </table>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Input fields for editing user information */}
                    <tbody>
                        <tr>
                            <td>
                                <div className='mb-3'>
                                    <label htmlFor='firstname' className='form-label'>
                                        Firstname
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        id='firstname'
                                        name='firstname'
                                        value={editedInfo.firstname}
                                        onChange={handleChange}
                                    />
                                </div>
                            </td>
                            <td> <div className='mb-3'>
                                <label htmlFor='firstname' className='form-label'>
                                    Contact #
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='userContact'
                                    name='userContact'
                                    value={editedInfo.userContact}
                                    onChange={handleChange}
                                />
                            </div></td>
                        </tr>
                        <tr>
                            <td>
                                <div className='mb-3'>
                                    <label htmlFor='firstname' className='form-label'>
                                        Lastname
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        id='lastname'
                                        name='lastname'
                                        value={editedInfo.lastname}
                                        onChange={handleChange}
                                    />
                                </div>
                            </td>
                            <td>
                                <div className='mb-3'>
                                    <label htmlFor='firstname' className='form-label'>
                                        Email
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        id='email'
                                        name='email'
                                        value={editedInfo.email}
                                        onChange={handleChange}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2'>
                                <div className='mb-3'>
                                    <label htmlFor='firstname' className='form-label'>
                                        Address
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        id='userAddress'
                                        name='userAddress'
                                        value={editedInfo.userAddress}
                                        onChange={handleChange}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    {/* Add similar input fields for other information like lastname, email, userContact, userAddress */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant='primary' onClick={handleSave}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default UserSettings;

import React from 'react';
import { Link } from 'react-router-dom';
import constants from '../../constants'

const BookingListItem = ({ item }) => {
    if (!item) return null;

    const handleDeleteEvent = (id) => {
        console.log(item)
        console.log(id)
        fetch(`${constants.ENDPOINT}/api/packages/${item.id}`, {
            method: 'DELETE',
            mode: 'cors'
        })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    console.log('Event deleted successfully...');
                    item.refresh()
                }
            })
            .catch(err => console.log(err))
    };

    return (
        <>
            <tr className="fw-normal" key={item.id}>
                <th>
                    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                        alt="avatar 1" style={{ width: `45px`, height: `auto` }} />
                    <span className="ms-2">{item.firstname}</span>
                </th>
                <td className="align-middle">
                    <span>{item.userContact}</span>
                </td>
                <td className="align-middle">
                <span>{item.userAddress}</span>
                </td>
                <td className="align-middle">
                <span>{item.packageCode}</span>
                </td>
                <td className="align-middle">
                    <span>{item.packagePrice}</span>
                </td>
                <td className="align-middle">
                    <span>{item.date}</span>
                </td>
                <td className="align-middle">
                <span>{item.refferenceNumber}</span>
                </td>
                {/* <td className="align-middle">
                    <Link type='button'><i className="fas fa-pencil fa-lg text-success me-3"></i></Link>
                    <Link type='button' onClick={handleDeleteEvent}><i className="fas fa-trash-alt fa-lg text-danger"></i></Link>
                </td> */}
            </tr>
        </>
    );
};

export default BookingListItem;
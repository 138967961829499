import React, { useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import './Navbar.css';
import Logo from '../../img/Logo.png';
import { useNavigate } from 'react-router-dom';


const Navbar = () => {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton(true);
  }, []);

  window.addEventListener('resize', showButton);

  const navigate = useNavigate()

  const logout = () => {
    localStorage.removeItem('userType')
    navigate('/')
  }

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <img src={Logo} alt='logo' className='nav-logo' />
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
                About
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/story' className='nav-links' onClick={closeMobileMenu}>
                Story
              </Link>
            </li>
            {
              localStorage.getItem('userType') ? (
                <>
                  <li className='nav-item'>
                    <Link to='/gigs' className='nav-links' onClick={closeMobileMenu}>
                      Gigs
                    </Link>
                  </li>
                  <li>
                    <div className="btn-group px-2" role="group" aria-label="Basic example">
                      {localStorage.getItem('userType') === 'user' ? (
                        <>
                          {button && <Link className='btn btn-primary' type='button' to='/dashboard/user/view'>DASHBOARD</Link>}
                        </>
                      ) : (
                        <>
                          {button && <Link className='btn btn-primary' type='button' to='/dashboard/admin/bookinglist'>DASHBOARD</Link>}
                        </>
                      )}
                      {button && <Link className='btn btn-danger' onClick={() => logout()}>LOG OUT</Link>}
                    </div>
                  </li>
                  <li>
                    <Link
                      className='nav-links-mobile'
                      onClick={() => {
                        localStorage.removeItem('userType');
                        logout();
                      }}
                    >
                      Logout
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li className='nav-item'>
                    {button && <Link className='btn btn-primary' type='button' to='/auth/login'>LOG IN</Link>}
                  </li>
                  <li>
                    <Link
                      type='button'
                      to='/auth/login'
                      className='nav-links-mobile'
                      onClick={closeMobileMenu}
                    >
                      Login
                    </Link>
                  </li>
                </>
              )
            }
          </ul>
        </div>
      </nav>
      <Outlet />
    </>
  );
}

export default Navbar;
import React, { useState, useEffect} from 'react';
import { Modal, Button, Container, Row, Col, Form } from 'react-bootstrap';
import './AddEvents.css';
import constants from '../constants'
import { useNavigate } from 'react-router-dom';



function MyVerticallyCenteredModal(props) {

    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if(token) {
            const accessToken = atob(token.split('.')[1])
            console.log(atob(token.split('.')[1]))
            if(accessToken.iat * 1000 < Date.now()) {
            localStorage.removeItem('token')
            navigate('/auth/login')
            } else {
    
            }
        } else {
            localStorage.removeItem('token');
            navigate('auth/login')
        }
        }, []);

    const [newEvent, setNewEvent] = useState({
        price: '',
        packageCode: '',
        info: '',
        image: 'image.file.filename'
    });

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
    
        if (name === 'image') {
            setNewEvent((prevEvent) => ({
                ...prevEvent,
                image: e.target.files[0],
            }));
            } else if (type === 'text') {
            setNewEvent((prevEvent) => ({
                ...prevEvent,
                [name]: value,
            }));
        }
    };

    const handleSubmitAddEvents = () => {
        const plus = { ...newEvent }

        if (!plus.price || !plus.packageCode || !plus.info || !plus.image) {
            console.log('Please fill in all required fields.');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('price', plus.price);
            formData.append('packageCode', plus.packageCode);
            formData.append('info', plus.info);
            formData.append('image', plus.image);

            fetch(`${constants.ENDPOINT}/api/packages`, {
                method: 'POST',
                mode: 'cors',
                body: formData
            })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    console.log('Adding Event is successful...')
                } else {
                    console.log('Error adding event!');
                }
            })

            } catch (error) {
            console.log('An error occurred:', error);
        }

        setNewEvent({
            price: '',
            packageCode: '',
            info: '',
            image: 'image.file.filename'
        });

    };

    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Form onSubmit={handleSubmitAddEvents}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark" id="contained-modal-title-vcenter">
                        Adding Events
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="grid-example">
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3 text-dark" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control
                                        placeholder ='Price'
                                        name='price'
                                        type='text'
                                        value={newEvent.price}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3 text-dark" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Package Code</Form.Label>
                                    <Form.Control
                                        placeholder ='Package'
                                        name='packageCode'
                                        type='text'
                                        value={newEvent.packageCode}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3 text-dark" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Information</Form.Label>
                                    <Form.Control
                                        placeholder ='Information'
                                        name='info'
                                        type='text'
                                        value={newEvent.info}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3 text-dark" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Upload Image</Form.Label>
                                    <Form.Control
                                        className="form-control"
                                        name='image'
                                        type='file'
                                        // ref={fileInputRef} 
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit">Add</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

const AddEvents = () => {
    const [modalShow, setModalShow] = useState(false);

    return (
        <Container>
            <img
                onClick={() => setModalShow(true)}
                width="25"
                height="25"
                src="https://img.icons8.com/fluency/48/add.png"
                alt="add-events"
            />
            <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
        </Container>
    );
};

export default AddEvents;

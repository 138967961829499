import React from "react";
import { Container, Row, Col, Form, FloatingLabel, Button } from "react-bootstrap";
import spotify from "../../img/icons/icon-spot.png";
import email from "../../img/icons/icon-email.png";
import cp from "../../img/icons/icon-cp.png";
import yt from "../../img/icons/icon-yt.png";
import fb from "../../img/icons/icon-fb.png";
import ig from "../../img/icons/icon-insta.png";
import logo from '../../img/Logo.png'
import './Footer.css'
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <footer className="footer mt-5 bg-dark text-white pt-5 pb-5">
      <Container>
        <Row >
          <Col className="Contact">
          <Form.Text className="text-white">
        Contact US
      </Form.Text><br />
      <Form.Text className="text-white">
        Send us a message
      </Form.Text>
              <FloatingLabel
                controlId="floatingInput"
                label="Full Name"
                className="mb-2 text-dark"
              >
                <Form.Control type="email" placeholder="name@example.com" />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="Contact #" className="mb-2 text-dark">
                <Form.Control type="text" placeholder="Contact #" />
              </FloatingLabel>
              <Form.Label>message</Form.Label>
        <Form.Control as="textarea" rows={4} />
        <Button variant="secondary" className="mt-2">SUBMIT</Button>{' '}
            
          </Col>
          <Col>
            <ul className="sitemap">
            <li><h6>Site Map</h6></li>
            <li></li>
            <li></li>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/story">Story</Link></li>
            <li><Link to="/story">Booking</Link></li>
            </ul>
          </Col>
          <Col>
            <img src={logo} alt="logo-footer" className='logo-footer' />
            <ul className="social-media">
            <li><img src={cp} alt="cp-icon" />0995-988-8149</li>
            <li><img src={email} alt="gmail-icon" />josephgaraofficial@gmail.com</li>
            <li><Link to='https://www.facebook.com/josephgaraofficial' target='_blank'><img src={fb} alt="fb-icon" />Joseph Gara Official FB</Link></li>
            <li><Link to='https://www.instagram.com/josephgaraofficial/' target='_blank'><img src={ig} alt="insta-icon" />Official Instagram Account</Link></li>
            <li><Link to='https://open.spotify.com/artist/31M83eWJM6J8qCaeUMLIWF' target='_blank'><img src={spotify} alt="spotify-icon" />Spotify</Link></li>
            <li><Link to='https://youtube.com/c/JosephGara' target='_blank'><img src={yt} alt="yt-icon" />Official Youtube Channel</Link></li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container className="footer-copyright text-center mt-3">
              <p>Copyright &copy; 2023 Joseph Gara</p>
            </Container>
    </footer>
  );
};

export default Footer;
